import Image from 'next/image';
import { z } from 'zod';
import { getHeroNavigationSchema } from '@api/common/browse';
import useIsCombatSportsUser from '@hooks/useIsCombatSportsUser';
import { ROUTES } from '@constants/route';
import MenuItem from './MenuItem';

type HoverMenuProps = {
  navData: z.infer<typeof getHeroNavigationSchema>;
  isTBNGUser?: boolean;
  isCityBeachUser: boolean;
  isKidStuffUser: boolean;
};

const HoverMenu = ({ navData, isTBNGUser, isCityBeachUser, isKidStuffUser }: HoverMenuProps) => {
  const { isCombatSportsUser } = useIsCombatSportsUser();
  return (
    <div className="flex gap-10 text-sm tablet:hidden">
      {navData?.map(item => (
        <MenuItem
          key={item.path}
          item={item}
          isCityBeachUser={isCityBeachUser}
          isKidStuffUser={isKidStuffUser}
          isCombatSportsUser={isCombatSportsUser}
        />
      ))}
      {isTBNGUser && (
        <div className="flex items-center gap-2">
          <MenuItem
            item={{
              name: 'First Nations',
              path: `${ROUTES.COMMON.FIRST_NATION_BRANDS}`,
              children: [],
            }}
          />
          <Image
            src="/assets/images/first-nations-logo.png"
            alt="First Nation"
            width={20}
            height={20}
            className="mt-[-0.5px]"
          />
        </div>
      )}
    </div>
  );
};

export default HoverMenu;
