import { useAuth } from '@hooks/useAuth';
import { DEMO_PARENT_IDS } from '@data/demo/demoUsers';
import { PROD_PARENT_IDS } from '@data/prod/prodUsers';
import { STAGING_PARENT_IDS } from '@data/staging/stagingUsers';
import { ROLES } from '@constants';

const getParentIdConfigForEnv = () => {
  switch (process.env.NEXT_PUBLIC_ENV) {
    case 'dev': {
      return null;
    }
    case 'staging': {
      return STAGING_PARENT_IDS.KIDSTUFF_USERS;
    }
    case 'demo': {
      return DEMO_PARENT_IDS.KIDSTUFF_USERS;
    }
    case 'prod': {
      return PROD_PARENT_IDS.KIDSTUFF_USERS;
    }
    default: {
      return null;
    }
  }
};

export const checkKidStuffUser = (parentId: number | undefined) => {
  if (!parentId) return false;

  const parentIdConfig = getParentIdConfigForEnv();
  if (!parentIdConfig) return false;

  return parentIdConfig.includes(parentId);
};

const useIsKidStuffUser = () => {
  const { isAuthenticated, user } = useAuth();

  const isRetailer = user?.role === ROLES.RETAILER;

  const isKidStuffUser =
    isAuthenticated &&
    checkKidStuffUser(isRetailer ? user?.parentRetailer?.id : user?.parentBrand?.id);

  return {
    isKidStuffUser: !!isKidStuffUser,
  };
};

export default useIsKidStuffUser;
