import { useEffect } from 'react';
import { cx } from '@ui/utils';
import { NavigationTree } from '@redux/common/commonSlice';
import NavActions from '../NavActions/NavActions';
import SearchBoxContainer from '../SearchBox/SearchBoxContainer';
import DynamicNavAccordion from './DynamicNavAccordion';
import styles from './MobileMenu.module.css';

type DynamicMobileMenuProps = {
  navigationTree: NavigationTree;
  isCityBeachUser: boolean;
  isKidStuffUser: boolean;
  isCombatSportsUser?: boolean;
};

const DynamicMobileMenu = ({
  navigationTree,
  isCityBeachUser,
  isKidStuffUser,
  isCombatSportsUser,
}: DynamicMobileMenuProps) => {
  useEffect(() => {
    // lock body scroll when mobile menu is mounted
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return (
    <div
      className={cx('flex h-full w-full animate-slide-down-fade-long flex-col', styles.mobileMenu)}
    >
      <div className="mt-6 flex flex-col items-center justify-center gap-8">
        <SearchBoxContainer />
        <div className="flex w-full items-center gap-5 text-sm">
          <NavActions actionClassName="flex-1" />
        </div>
      </div>
      <div className="mb-2 mt-5 flex-1 overflow-y-auto overflow-x-hidden text-white">
        <DynamicNavAccordion
          navigationTree={navigationTree.data}
          isCityBeachUser={isCityBeachUser}
          isKidStuffUser={isKidStuffUser}
          isCombatSportsUser={isCombatSportsUser}
          brandSlug={navigationTree.brandSlug}
        />
      </div>
    </div>
  );
};

export default DynamicMobileMenu;
