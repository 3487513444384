import { z } from 'zod';
import { Accordion } from '@ui/core';
import { cx } from '@ui/utils';
import { getHeroNavigationSchema } from '@api/common/browse';
import { ROUTES } from '@constants/route';
import NavLink from '../NavLink';

type NavAccordionProps = {
  navData: z.infer<typeof getHeroNavigationSchema>;
  depth?: number;
  isCityBeachUser: boolean;
  isKidStuffUser: boolean;
  isCombatSportsUser?: boolean;
};

const styles = {
  label: 'py-1.5',
  itemTitle: 'text-sm',
  content: 'py-0',
  control: 'px-0 pl-0.5',
  item: 'border-none',
  chevron: 'text-white w-4 h-4',
};

const NavAccordion = ({
  navData,
  depth = 0,
  isCityBeachUser,
  isKidStuffUser,
  isCombatSportsUser,
}: NavAccordionProps) => {
  const renderNavAccordion = () => {
    return navData?.map(item => {
      const hasChildren = item?.children?.length > 0;

      return (
        <Accordion.Item key={item.path} value={item.path}>
          <Accordion.Control
            classNames={{
              chevron: cx(
                !hasChildren && 'hidden',
                (isCityBeachUser || isKidStuffUser || isCombatSportsUser) && '!text-andisor-navy'
              ),
            }}
          >
            <NavLink
              className={cx(
                'text-white',
                (isCityBeachUser || isKidStuffUser || isCombatSportsUser) && 'text-andisor-navy'
              )}
              href={item.path.replace('/navigation', ROUTES.BUYERS.BROWSE)}
              highlightActiveLink={depth === 0}
              highlightColor={
                isCityBeachUser || isKidStuffUser || isCombatSportsUser
                  ? 'text-andisor-blue'
                  : undefined
              }
            >
              <span className="text-base mobile:text-sm">{item.name}</span>
            </NavLink>
          </Accordion.Control>
          <Accordion.Panel>
            {hasChildren && (
              <NavAccordion
                navData={item.children}
                depth={depth + 1}
                isCityBeachUser={isCityBeachUser}
                isKidStuffUser={isKidStuffUser}
                isCombatSportsUser={isCombatSportsUser}
              />
            )}
          </Accordion.Panel>
        </Accordion.Item>
      );
    });
  };

  return <Accordion classNames={styles}>{renderNavAccordion()}</Accordion>;
};

export default NavAccordion;
