import Image from 'next/image';
import Link from 'next/link';
import { Button, Popover } from '@ui/core';
import { cx } from '@ui/utils';
import {
  IconArrowLeft,
  IconCheck,
  IconChevronDown,
  IconShoppingBag,
  IconTable,
  IconTruckDelivery,
} from '@ui/icons';
import Notification from '@components/notifications/Notification';
import { useAnimateCartIcon } from '@hooks/useAnimateCartIcon';
import { clearUserState, useAuth } from '@hooks/useAuth';
import useDropshipCheck from '@hooks/useDropshipCheck';
import useIsCityBeachUser from '@hooks/useIsCityBeachUser';
import useIsFrontlineUser from '@hooks/useIsFrontlineUser';
import useIsKidStuffUser from '@hooks/useIsKidStuffUser';
import useIsMyerUser from '@hooks/useIsMyerUser';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';
import { ROLES } from '@constants';
import { ROUTES } from '@constants/route';

const NavActions = ({ actionClassName }: { actionClassName?: string }) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const { shouldAnimateCartIcon } = useAnimateCartIcon();
  const { isMyerUser } = useIsMyerUser();
  const { isFrontlineUser } = useIsFrontlineUser();
  const { isNewBalanceUser } = useIsNewBalanceUser();
  const { isCityBeachUser } = useIsCityBeachUser();
  const { isKidStuffUser } = useIsKidStuffUser();

  // check if the user has logged in but not onboarded yet
  const isOnboardingPending = isAuthenticated && !user!.role;

  const isRetailer = user?.role === ROLES.RETAILER;

  const { isRetailerEligibleToDropship } = useDropshipCheck();

  console.log('## the user :', user);

  return (
    <>
      {!isLoading && !isAuthenticated && (
        <>
          <Button
            className={actionClassName}
            component={Link}
            href={ROUTES.COMMON.SIGNUP}
            variant="outline-text-light"
            color="red"
            size="sm"
            leftArrow
          >
            Sign up
          </Button>
          <Button
            className={actionClassName}
            component="a"
            href={`${ROUTES.NEXT_API.AUTH.LOGIN}?returnTo=${window.location.pathname}&prompt=login`}
            variant="filled"
            color="red"
            size="sm"
          >
            Log In
          </Button>
        </>
      )}
      {isAuthenticated && (
        <>
          <Popover width={140} offset={10} position="bottom" zIndex={1000} withArrow>
            <Popover.Target>
              <button type="button" className="flex items-center gap-2 py-0.5">
                {user?.profilePicture && (
                  <Image
                    src={user.profilePicture}
                    width={31}
                    height={31}
                    alt="profile picture"
                    className="rounded-full"
                  />
                )}
                <span>{user?.firstName}</span>
                <IconChevronDown width={15} />
              </button>
            </Popover.Target>
            <Popover.Dropdown className="flex flex-col gap-4 font-body text-sm text-andisor-navy shadow-md">
              {isOnboardingPending && (
                <Link
                  href={ROUTES.COMMON.TYPE_SELECTION}
                  className="transition-colors hover:text-black/60"
                >
                  Setup Profile
                </Link>
              )}
              {user?.role === ROLES.ADMIN && (
                <>
                  <Link
                    href={ROUTES.ADMIN.DASHBOARD_BRANDS}
                    className="transition-colors hover:text-black/60"
                  >
                    Dashboard
                  </Link>
                  <Link href={ROUTES.ADMIN.TAGS} className="transition-colors hover:text-black/60">
                    Tags
                  </Link>
                  <Link
                    href={ROUTES.ADMIN.COLLECTION}
                    className="transition-colors hover:text-black/60"
                  >
                    Collections
                  </Link>
                </>
              )}
              {user?.role === ROLES.BRAND && (
                <>
                  <Link
                    href={ROUTES.SELLERS.ORDERS}
                    className="transition-colors hover:text-black/60"
                  >
                    My Orders
                  </Link>
                  <Link
                    href={ROUTES.SELLERS.SETTINGS}
                    className="transition-colors hover:text-black/60"
                  >
                    My Profile
                  </Link>
                </>
              )}
              {user?.role === ROLES.RETAILER && (
                <>
                  <Link
                    href={ROUTES.BUYERS.ORDERS}
                    className="transition-colors hover:text-black/60"
                  >
                    My Orders
                  </Link>
                  {isRetailerEligibleToDropship && (
                    <Link
                      href={ROUTES.BUYERS.DROPSHIP_REQUESTS}
                      className="transition-colors hover:text-black/60"
                    >
                      My Dropship
                    </Link>
                  )}
                  <Link
                    href={ROUTES.BUYERS.SETTINGS}
                    className="transition-colors hover:text-black/60"
                  >
                    My Profile
                  </Link>
                  <Link
                    href={ROUTES.BUYERS.SUPPLIERS}
                    className="transition-colors hover:text-black/60"
                  >
                    My Suppliers
                  </Link>
                  {process.env.NEXT_PUBLIC_ENV === 'demo' &&
                    (isMyerUser ||
                      isFrontlineUser ||
                      isCityBeachUser ||
                      isKidStuffUser ||
                      isNewBalanceUser) && (
                      <Link
                        href={ROUTES.BUYERS.ANALYTICS}
                        className="transition-colors hover:text-black/60"
                      >
                        Analytics
                      </Link>
                    )}
                </>
              )}
              <div className="flex items-center gap-2 border-t pt-2 text-xs">
                <IconArrowLeft width={16} className="text-black/30" />
                <a
                  href={ROUTES.NEXT_API.AUTH.LOGOUT}
                  onClick={clearUserState}
                  className="w-full transition-colors hover:text-black/60"
                >
                  Logout
                </a>
              </div>
            </Popover.Dropdown>
          </Popover>
          <Notification />
          {isRetailer && (
            <div className="ml-auto flex items-center gap-5">
              <Link
                href={ROUTES.BUYERS.ORDER_FORM}
                title="Order Form"
                className="relative ml-2 transition-colors hover:text-andisor-blue"
              >
                <IconTable size={21} stroke={1.4} />
              </Link>
              <Link
                href={ROUTES.BUYERS.CART_CHECKOUT}
                title="Checkout"
                className={cx(
                  'relative transition-colors hover:text-andisor-blue',
                  shouldAnimateCartIcon && 'animate-tada'
                )}
              >
                <IconShoppingBag size={24} stroke={1.4} />
                {shouldAnimateCartIcon && (
                  <IconCheck
                    className="absolute right-[-12px] top-[-8px] text-green-500"
                    size="18"
                    stroke={3}
                  />
                )}
              </Link>
              {isRetailerEligibleToDropship && (
                <Link
                  href={ROUTES.BUYERS.DROPSHIP_CART_CHECKOUT}
                  title="Dropship Checkout"
                  className="mt-1 transition-colors hover:text-andisor-blue"
                >
                  <IconTruckDelivery size={24} stroke={1.4} />
                </Link>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NavActions;
