export default [
  {
    name: 'Women',
    targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
    userCategoryPaths: [],
    productCategoryPaths: [],
    children: [
      {
        name: 'Shoes',
        targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
        userCategoryPaths: [],
        productCategoryPaths: ['/product-category/shoes'],
        children: [
          {
            name: 'Boots',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/boots'],
            children: [
              {
                name: 'Ugg Boots',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/boots/ugg-boots'],
                children: [],
              },
              {
                name: 'Ankle Boots',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/boots/ankle-boots'],
                children: [],
              },
            ],
          },
          {
            name: 'Sneakers',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/sneakers'],
            children: [
              {
                name: 'Active Sneakers',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/sneakers/active-sneakers'],
                children: [],
              },
            ],
          },
          {
            name: 'Performance',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/athletic-shoes'],
            children: [],
          },
          {
            name: 'Shoe care & Accessories',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/shoe-care-and-accessories'],
            children: [
              {
                name: 'Shoe Cleaners',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-cleaners',
                ],
                children: [],
              },
              {
                name: 'Shoe Accessories',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-accessories',
                ],
                children: [],
              },
              {
                name: 'Shoe Brush',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-brush',
                ],
                children: [],
              },
              {
                name: 'Shoe Deodorizer',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-deodorizer',
                ],
                children: [],
              },
              {
                name: 'Shoe Polish',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-polish',
                ],
                children: [],
              },
              {
                name: 'Shoe Trees & Shapers',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-trees-and-shapers',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Slippers',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/slippers'],
            children: [],
          },
          {
            name: 'Sandals & Thongs',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/thongs-and-sandals'],
            children: [
              {
                name: 'Flat Sandals',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/thongs-and-sandals/flat-sandals'],
                children: [],
              },
            ],
          },
          {
            name: 'Heels & Pumps',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/heels-and-pumps'],
            children: [
              {
                name: 'High Heels',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/heels-and-pumps/high-heels'],
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Clothing',
        targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
        userCategoryPaths: [],
        productCategoryPaths: ['/product-category/clothing'],
        children: [
          {
            name: 'Activewear',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/activewear'],
            children: [
              {
                name: 'Fitness Tops',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/activewear/activewear-tops/t-shirts',
                ],
                children: [],
              },
              {
                name: 'Fitness Shorts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/activewear/activewear-shorts/fitness-shorts',
                ],
                children: [],
              },
              {
                name: 'Sports Bra',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/activewear/sports-bras'],
                children: [],
              },
            ],
          },
          {
            name: 'Dresses',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/dresses'],
            children: [
              {
                name: 'Cocktail & Party Dresses',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/dresses/cocktail-and-party-dresses',
                ],
                children: [],
              },
              {
                name: 'Kaftans & Beach Dresses',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/dresses/kaftans-and-beach-dresses',
                ],
                children: [],
              },
              {
                name: 'Mini Dresses',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/dresses/mini-dresses'],
                children: [],
              },
              {
                name: 'Formal Dresses',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/dresses/formal-dresses'],
                children: [],
              },
              {
                name: 'Maxi Dresses',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/dresses/maxi-dresses'],
                children: [],
              },
              {
                name: 'Midi Dresses',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/dresses/midi-dresses'],
                children: [],
              },
            ],
          },
          {
            name: 'Jumpsuits & Playsuits',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/jumpsuits-and-playsuits'],
            children: [
              {
                name: 'Jumpsuits',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/jumpsuits-and-playsuits/jumpsuits',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Knitwear & Jumpers',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/knitwear'],
            children: [
              {
                name: 'Cardigans',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/knitwear/cardigans'],
                children: [],
              },
              {
                name: 'Sweaters',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/knitwear/sweaters'],
                children: [],
              },
            ],
          },
          {
            name: 'Lingerie',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/lingerie'],
            children: [
              {
                name: 'Socks',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/socks/socks'],
                children: [],
              },
              {
                name: 'Camisoles & Chemises',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/lingerie/camisoles-and-chemises/camisoles',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Coats & Jackets',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/coats-and-jackets'],
            children: [
              {
                name: 'Jackets',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/coats-and-jackets/jackets'],
                children: [],
              },
              {
                name: 'Vests',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/coats-and-jackets/vests'],
                children: [],
              },
              {
                name: 'Bomber Jackets',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/coats-and-jackets/bomber-jackets',
                ],
                children: [],
              },
              {
                name: 'Denim Jacket',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/coats-and-jackets/denim-jackets',
                ],
                children: [],
              },
              {
                name: 'Winter Coats',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/coats-and-jackets/winter-coats'],
                children: [],
              },
              {
                name: 'Blazers',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/coats-and-jackets/blazers'],
                children: [],
              },
              {
                name: 'Capes',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/coats-and-jackets/capes'],
                children: [],
              },
            ],
          },
          {
            name: 'Jeans',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/jeans'],
            children: [
              {
                name: 'High Waist Jeans',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/jeans/high-waist-jeans'],
                children: [],
              },
              {
                name: 'Skinny Jeans',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/jeans/skinny-jeans'],
                children: [],
              },
              {
                name: 'Flare & Wideleg Jeans',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/jeans/flare-and-wideleg-jeans'],
                children: [],
              },
            ],
          },
          {
            name: 'Shorts',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/shorts'],
            children: [
              {
                name: 'Casual Shorts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/shorts/casual-shorts'],
                children: [],
              },
            ],
          },
          {
            name: 'Tops',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/tops'],
            children: [
              {
                name: 'Tunics',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/tunics'],
                children: [],
              },
              {
                name: 'Lace Tops',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/lace-tops'],
                children: [],
              },
              {
                name: 'Blouses',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/blouses'],
                children: [],
              },
              {
                name: 'Off-Shoulder Tops',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/off-shoulder-tops'],
                children: [],
              },
              {
                name: 'Crop Tops',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/crop-tops'],
                children: [],
              },
              {
                name: 'Shirts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/shirts'],
                children: [],
              },
            ],
          },
          {
            name: 'Sleepwear & Loungewear',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/sleepwear'],
            children: [
              {
                name: 'Pyjama Sets',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/sleepwear/pyjama-sets'],
                children: [],
              },
              {
                name: 'Pyjama Bottoms',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/sleepwear/pyjama-pants'],
                children: [],
              },
              {
                name: 'Dressing Gowns & Robes',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/sleepwear/dressing-gowns-and-robes',
                ],
                children: [],
              },
              {
                name: 'Nightgowns & Shirts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/sleepwear/nightgowns-and-shirts',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Swimwear',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/swimwear'],
            children: [
              {
                name: 'One Piece / Swimsuit',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/one-piece-swimsuit'],
                children: [],
              },
              {
                name: 'Rash Vests and suits',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/rash-vests'],
                children: [],
              },
              {
                name: 'Bikini Bottoms',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/bikini-bottoms'],
                children: [],
              },
              {
                name: 'Swim skirts and shorts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/swimwear/swim-skirts-and-shorts',
                ],
                children: [],
              },
              {
                name: 'Swim dresses',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/swim-dresses'],
                children: [],
              },
              {
                name: 'Bikini Tops',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/bikini-tops'],
                children: [],
              },
              {
                name: 'Swim Briefs',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/swim-briefs'],
                children: [],
              },
            ],
          },
          {
            name: 'Pants',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/pants'],
            children: [
              {
                name: 'Leggings',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/pants/leggings'],
                children: [],
              },
              {
                name: 'Pants',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/pants/pants'],
                children: [],
              },
              {
                name: 'Tapered Pants',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/pants/tapered-pants'],
                children: [],
              },
              {
                name: 'Dress Pants',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/pants/dress-pants'],
                children: [],
              },
              {
                name: 'Cropped Pants',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/pants/cropped-pants'],
                children: [],
              },
            ],
          },
          {
            name: 'Skirts',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/skirts'],
            children: [
              {
                name: 'Mini Skirts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/skirts/mini-skirts'],
                children: [],
              },
              {
                name: 'Denim Skirts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/skirts/denim-skirts'],
                children: [],
              },
              {
                name: 'Pencil Skirts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/skirts/pencil-skirts'],
                children: [],
              },
              {
                name: 'Pleated Skirts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/skirts/pleated-skirts'],
                children: [],
              },
              {
                name: 'Maxi Skirts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/skirts/maxi-skirts'],
                children: [],
              },
            ],
          },
          {
            name: 'Maternity',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/maternity/dresses'],
            children: [
              {
                name: 'Nursing Dress',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/maternity/dresses/nursing-dress',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'T-shirts & Singlets',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/tops'],
            children: [
              {
                name: 'Singlets',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/tops/singlets-and-tanks/singlets',
                ],
                children: [],
              },
              {
                name: 'T-Shirts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/t-shirts/t-shirts'],
                children: [],
              },
              {
                name: 'Polos',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/polos/short-sleeve-polos'],
                children: [],
              },
            ],
          },
          {
            name: 'Sweatshirts & Hoodies',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/sweatshirts-and-hoodies'],
            children: [
              {
                name: 'Sweatshirts',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/sweatshirts-and-hoodies/sweatshirts',
                ],
                children: [],
              },
              {
                name: 'Hoodies',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/sweatshirts-and-hoodies/hoodies',
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Accessories',
        targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
        userCategoryPaths: [],
        productCategoryPaths: [
          '/product-category/accessories',
          '/product-category/sporting-goods/outdoor-recreation/swimming',
        ],
        children: [
          {
            name: 'Sunglasses',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/accessories/fashion-accessories/sunglasses'],
            children: [
              {
                name: 'Sunglasses',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/sunglasses/sunglasses',
                ],
                children: [],
              },
              {
                name: 'Sunglasses Accessories',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/sunglasses/sunglasses-accessories',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Jewellery',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/accessories/fashion-accessories/jewellery'],
            children: [
              {
                name: 'Rings',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/jewellery/rings',
                ],
                children: [],
              },
              {
                name: 'Brooches and Pins',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/jewellery/brooches-and-pins',
                ],
                children: [],
              },
              {
                name: 'Earrings',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/jewellery/earrings',
                ],
                children: [],
              },
              {
                name: 'Necklaces',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/jewellery/necklaces',
                ],
                children: [],
              },
              {
                name: 'Jewellery Boxes & Rolls',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/jewellery/jewellery-boxes-and-rolls',
                ],
                children: [],
              },
              {
                name: 'Necklace Sets',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/jewellery/necklace-sets',
                ],
                children: [],
              },
              {
                name: 'Bracelets',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/jewellery/bracelets',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Swimming Accessories',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/sporting-goods/outdoor-recreation/swimming'],
            children: [
              {
                name: 'Swim Caps',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/sporting-goods/outdoor-recreation/swimming/swim-caps',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Bags',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/accessories/bags'],
            children: [
              {
                name: 'Shoulder Bags',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/shoulder-bags'],
                children: [],
              },
              {
                name: 'Clutches & Pouches',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/clutches-and-pouches'],
                children: [],
              },
              {
                name: 'Tote Bags',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/tote-bags'],
                children: [],
              },
              {
                name: 'Backpacks',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/backpacks'],
                children: [],
              },
              {
                name: 'Make Up & Cosmetic Bags',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/bags/make-up-and-cosmetic-bags',
                ],
                children: [],
              },
              {
                name: 'Beach Bags',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/beach-bags'],
                children: [],
              },
              {
                name: 'Cross-Body Bags',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/cross-body-bags'],
                children: [],
              },
              {
                name: 'Laptop & Business Bags',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/bags/laptop-and-business-bags',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Socks',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/socks'],
            children: [
              {
                name: 'Crew Socks',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/socks/crew-socks'],
                children: [],
              },
            ],
          },
          {
            name: 'Hats, Scarves & Gloves',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves',
            ],
            children: [
              {
                name: 'Beanies',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/beanies',
                ],
                children: [],
              },
              {
                name: 'Wraps',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/wraps',
                ],
                children: [],
              },
              {
                name: 'Scarves',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/scarves',
                ],
                children: [],
              },
              {
                name: 'Caps',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/caps',
                ],
                children: [],
              },
              {
                name: 'Face Masks',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/face-masks',
                ],
                children: [],
              },
              {
                name: 'Poncho',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/poncho',
                ],
                children: [],
              },
              {
                name: 'Hats',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/hats',
                ],
                children: [],
              },
              {
                name: 'Gloves',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/gloves',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Wallets',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/accessories/fashion-accessories/wallets'],
            children: [
              {
                name: 'Clutch',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/wallets/clutch',
                ],
                children: [],
              },
              {
                name: 'Zip around',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/wallets/zip-around-wallet',
                ],
                children: [],
              },
              {
                name: 'Card Holders',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/wallets/card-holders',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Fascinators & Hair Accessories',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/accessories/fashion-accessories/fascinators-and-hair-accessories',
            ],
            children: [
              {
                name: 'Headbands',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/fascinators-and-hair-accessories/headbands',
                ],
                children: [],
              },
              {
                name: 'Scrunchies & Hair Ties',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/fascinators-and-hair-accessories/scrunchies-and-hair-ties',
                ],
                children: [],
              },
              {
                name: 'Hair Clips',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/fascinators-and-hair-accessories/hair-clips',
                ],
                children: [],
              },
              {
                name: 'Claw Clips',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/fascinators-and-hair-accessories/claw-clips',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Travel & Luggage',
            targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/accessories/luggage-and-travel-accessories',
              '/product-category/accessories/fashion-accessories/wallets/travel-wallets',
              '/product-category/accessories/bags/toiletry-bags',
              '/product-category/accessories/bags/camera-bags',
              '/product-category/accessories/bags/luggage/hard-suitcases',
            ],
            children: [
              {
                name: 'Toiletry bags',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/toiletry-bags'],
                children: [],
              },
              {
                name: 'Camera Bags',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/camera-bags'],
                children: [],
              },
              {
                name: 'Travel Wallets',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/wallets/travel-wallets',
                ],
                children: [],
              },
              {
                name: 'Passport Holders',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/luggage/hard-suitcases'],
                children: [],
              },
              {
                name: 'Travel Accessories',
                targetAudiencePaths: ['/target-audience/women', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/luggage-and-travel-accessories/travel-adaptors',
                ],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Men',
    targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
    userCategoryPaths: [],
    productCategoryPaths: [],
    children: [
      {
        name: 'Shoes',
        targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
        userCategoryPaths: [],
        productCategoryPaths: ['/product-category/shoes'],
        children: [
          {
            name: 'Loafers & Slip Ons',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/shoes/flats/loafers',
              '/product-category/shoes/dress-shoes/moccasins',
            ],
            children: [
              {
                name: 'Flats & Loafers',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/flats/loafers'],
                children: [],
              },
              {
                name: 'Moccasins',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/dress-shoes/moccasins'],
                children: [],
              },
            ],
          },
          {
            name: 'Dress Shoes',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/dress-shoes'],
            children: [
              {
                name: 'Derby',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/dress-shoes/derby'],
                children: [],
              },
            ],
          },
          {
            name: 'Sandals, Slides & Thongs',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/thongs-and-sandals'],
            children: [
              {
                name: 'Sandals',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/thongs-and-sandals/sandals'],
                children: [],
              },
            ],
          },
          {
            name: 'Shoe Care & Accessories',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/shoe-care-and-accessories'],
            children: [
              {
                name: 'Shoe Cleaners',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-cleaners',
                ],
                children: [],
              },
              {
                name: 'Shoe Deodorizer',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-deodorizer',
                ],
                children: [],
              },
              {
                name: 'Shoe Accessories',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-accessories',
                ],
                children: [],
              },
              {
                name: 'Shoe Brush',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-brush',
                ],
                children: [],
              },
              {
                name: 'Shoe Trees & Shapers',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-trees-and-shapers',
                ],
                children: [],
              },
              {
                name: 'Shoe Polish',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/shoes/shoe-care-and-accessories/shoe-polish',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Slippers',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/slippers'],
            children: [],
          },
          {
            name: 'Sneakers',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/sneakers'],
            children: [
              {
                name: 'Lifestyle Sneakers',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/sneakers/lifestyle-sneakers'],
                children: [],
              },
            ],
          },
          {
            name: 'Boots',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/boots'],
            children: [
              {
                name: 'Ankle Boots',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/boots/ankle-boots'],
                children: [],
              },
              {
                name: 'Chelsea Boots',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/boots/chelsea-boots'],
                children: [],
              },
              {
                name: 'Lace-up Boots',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/boots/lace-up-boots'],
                children: [],
              },
              {
                name: 'Flat Boots',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/boots/flat-boots'],
                children: [],
              },
              {
                name: 'Ugg Boots',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/boots/ugg-boots'],
                children: [],
              },
            ],
          },
          {
            name: 'Performance',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes/athletic-shoes'],
            children: [],
          },
        ],
      },
      {
        name: 'Accessories',
        targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
        userCategoryPaths: [],
        productCategoryPaths: [
          '/product-category/accessories',
          '/product-category/sporting-goods/fitness-accessories',
        ],
        children: [
          {
            name: 'Bags',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/accessories/bags'],
            children: [
              {
                name: 'Satchels & Messenger Bags',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/messenger-bags'],
                children: [],
              },
              {
                name: 'Briefcases & Laptop Bags',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/briefcases'],
                children: [],
              },
              {
                name: 'Travel & Weekend Bags',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/bags/travel-and-weekend-bags',
                ],
                children: [],
              },
              {
                name: 'Backpacks',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/backpacks'],
                children: [],
              },
              {
                name: 'Toiletry Bags',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/toiletry-bags'],
                children: [],
              },
              {
                name: 'Tote Bags',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/tote-bags'],
                children: [],
              },
            ],
          },
          {
            name: 'Sports Equipment',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/sporting-goods/fitness-accessories'],
            children: [
              {
                name: 'Water Bottles',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/sporting-goods/fitness-accessories/drink-bottles/insulated-bottle',
                ],
                children: [],
              },
              {
                name: 'Training Equipment',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/sporting-goods/fitness-accessories/fitness-mats',
                ],
                children: [],
              },
              {
                name: 'Gym Towels',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/sporting-goods/fitness-accessories/gym-towels',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Ties, Pocket Squares & Handkerchiefs',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/accessories/fashion-accessories/ties-and-cufflinks',
            ],
            children: [
              {
                name: 'Tie Clips',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/ties-and-cufflinks/tie-clips',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Jewellery & Cufflinks',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/accessories/fashion-accessories/jewellery'],
            children: [
              {
                name: 'Cufflinks',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/ties-and-cufflinks/cufflinks',
                ],
                children: [],
              },
              {
                name: 'Bracelets',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/jewellery/bracelets',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Hats, Scarves & Gloves',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves',
            ],
            children: [
              {
                name: 'Hats',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/hats',
                ],
                children: [],
              },
              {
                name: 'Scarves',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/scarves',
                ],
                children: [],
              },
              {
                name: 'Caps',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/caps',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Wallets',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/accessories/fashion-accessories/wallets'],
            children: [
              {
                name: 'Bifold',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/wallets/bifold-wallet',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Travel & Luggage',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/accessories/luggage-and-travel-accessories',
              '/product-category/accessories/fashion-accessories/wallets/travel-wallets',
              '/product-category/accessories/bags/toiletry-bags',
              '/product-category/accessories/bags/camera-bags',
              '/product-category/accessories/bags/luggage/hard-suitcases',
            ],
            children: [
              {
                name: 'Travel Wallets',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/wallets/travel-wallets',
                ],
                children: [],
              },
              {
                name: 'Travel Accessories',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/luggage-and-travel-accessories/travel-adaptors',
                ],
                children: [],
              },
              {
                name: 'Camera Bags',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/camera-bags'],
                children: [],
              },
            ],
          },
          {
            name: 'PPE',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/accessories/ppe'],
            children: [
              {
                name: 'PPE',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/ppe/ppe'],
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Clothing',
        targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
        userCategoryPaths: [],
        productCategoryPaths: ['/product-category/clothing'],
        children: [
          {
            name: 'Activewear',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/activewear'],
            children: [
              {
                name: 'Fitness Shorts',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/activewear/activewear-shorts/fitness-shorts',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Shirts & Polos',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/clothing/tops/polos',
              '/product-category/clothing/tops/shirts',
            ],
            children: [
              {
                name: 'Polo Shirts',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/polos/short-sleeve-polos'],
                children: [],
              },
              {
                name: 'Casual Shirts',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/tops/shirts/short-sleeve-shirts',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Pants',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/pants'],
            children: [
              {
                name: 'Pants',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/pants/pants'],
                children: [],
              },
            ],
          },
          {
            name: 'Swimwear',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/swimwear'],
            children: [
              {
                name: 'Boardshorts',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/boardshorts'],
                children: [],
              },
              {
                name: 'Swim Trunks',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/swim-trunks'],
                children: [],
              },
            ],
          },
          {
            name: 'Sweats & Hoodies',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/sweatshirts-and-hoodies'],
            children: [
              {
                name: 'Hoodies',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/sweatshirts-and-hoodies/hoodies',
                ],
                children: [],
              },
              {
                name: 'Sweatshirts',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/sweatshirts-and-hoodies/sweatshirts',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Sleepwear',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/sleepwear'],
            children: [
              {
                name: 'Dressing Gowns & Robes',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/sleepwear/dressing-gowns-and-robes',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'T-Shirts & Singlets',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/tops/t-shirts'],
            children: [
              {
                name: 'T-Shirts',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/t-shirts/t-shirts'],
                children: [],
              },
            ],
          },
          {
            name: 'Socks & Underwear',
            targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/clothing/socks',
              '/product-category/clothing/underwear',
            ],
            children: [
              {
                name: 'Multi-Packs',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/socks/multi-packs-socks'],
                children: [],
              },
              {
                name: 'Boxers',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/underwear/boxers'],
                children: [],
              },
              {
                name: 'Ankle Socks',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/socks/ankle-socks'],
                children: [],
              },
              {
                name: 'Knee High Socks',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/socks/knee-high-socks'],
                children: [],
              },
              {
                name: 'Crew Socks',
                targetAudiencePaths: ['/target-audience/men', '/target-audience/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/socks/crew-socks'],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Kids',
    targetAudiencePaths: ['/target-audience/kids'],
    userCategoryPaths: [],
    productCategoryPaths: [],
    children: [
      {
        name: 'Girlswear (3-16 years)',
        targetAudiencePaths: ['/target-audience/kids/girls', '/target-audience/kids/unisex'],
        userCategoryPaths: [],
        productCategoryPaths: ['/product-category/clothing', '/product-category/shoes'],
        children: [
          {
            name: 'Dresses',
            targetAudiencePaths: ['/target-audience/kids/girls', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/dresses'],
            children: [
              {
                name: 'Dresses',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/dresses/midi-dresses'],
                children: [],
              },
            ],
          },
          {
            name: 'Tops & T-Shirts',
            targetAudiencePaths: ['/target-audience/kids/girls', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/tops'],
            children: [
              {
                name: 'T-Shirts',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/t-shirts/t-shirts'],
                children: [],
              },
              {
                name: 'Singlets',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/tops/singlets-and-tanks/singlets',
                ],
                children: [],
              },
              {
                name: 'Shirts & Blouses',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/shirts/shirts'],
                children: [],
              },
              {
                name: 'Tops',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/tops'],
                children: [],
              },
            ],
          },
          {
            name: 'Bottoms',
            targetAudiencePaths: ['/target-audience/kids/girls', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/clothing/skirts',
              '/product-category/clothing/shorts',
              '/product-category/clothing/pants',
            ],
            children: [
              {
                name: 'Skirt',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/skirts/midi-skirts'],
                children: [],
              },
              {
                name: 'Shorts',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/shorts/casual-shorts'],
                children: [],
              },
              {
                name: 'Leggings',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/pants/leggings'],
                children: [],
              },
              {
                name: 'Pants',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/pants/pants'],
                children: [],
              },
            ],
          },
          {
            name: 'Jumpsuits & Playsuits',
            targetAudiencePaths: ['/target-audience/kids/girls', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/jumpsuits-and-playsuits'],
            children: [
              {
                name: 'Jumpsuits',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/jumpsuits-and-playsuits/jumpsuits',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Shoes',
            targetAudiencePaths: ['/target-audience/kids/girls', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes'],
            children: [
              {
                name: 'Sandals & Thongs',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/sandals-and-thongs/flat-sandals'],
                children: [],
              },
            ],
          },
          {
            name: 'Swimwear',
            targetAudiencePaths: ['/target-audience/kids/girls', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/swimwear'],
            children: [
              {
                name: 'One Piece / Swimsuit',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/one-piece-swimsuit'],
                children: [],
              },
              {
                name: 'Bikini Set',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/bikini-set'],
                children: [],
              },
              {
                name: 'Rash Suits',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/rash-suits'],
                children: [],
              },
            ],
          },
          {
            name: 'Knitwear, Jumpers & Sweats',
            targetAudiencePaths: ['/target-audience/kids/girls', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/sweatshirts-and-hoodies'],
            children: [
              {
                name: 'Sweatshirts',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/sweatshirts-and-hoodies/sweatshirts',
                ],
                children: [],
              },
              {
                name: 'Hoodies',
                targetAudiencePaths: [
                  '/target-audience/kids/girls',
                  '/target-audience/kids/unisex',
                ],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/sweatshirts-and-hoodies/hoodies',
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Kids Accessories',
        targetAudiencePaths: ['/target-audience/kids'],
        userCategoryPaths: [],
        productCategoryPaths: ['/product-category/accessories'],
        children: [
          {
            name: 'Sunglasses',
            targetAudiencePaths: ['/target-audience/kids'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/accessories/fashion-accessories/sunglasses'],
            children: [
              {
                name: 'Sunglasses',
                targetAudiencePaths: ['/target-audience/kids'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/sunglasses/sunglasses',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Bags',
            targetAudiencePaths: ['/target-audience/kids'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/accessories/bags'],
            children: [
              {
                name: 'Backpacks',
                targetAudiencePaths: ['/target-audience/kids'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/backpacks'],
                children: [],
              },
              {
                name: 'Tote Bags',
                targetAudiencePaths: ['/target-audience/kids'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/accessories/bags/tote-bags'],
                children: [],
              },
            ],
          },
          {
            name: 'Wallets & Keychains',
            targetAudiencePaths: ['/target-audience/kids'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/accessories/fashion-accessories/jewellery'],
            children: [
              {
                name: 'Lapel Pin & Button',
                targetAudiencePaths: ['/target-audience/kids'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/jewellery/lapel-pin-and-button',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Hair Accessories',
            targetAudiencePaths: ['/target-audience/kids'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/accessories/fashion-accessories/fascinators-and-hair-accessories',
            ],
            children: [
              {
                name: 'Hair Claws & Clips',
                targetAudiencePaths: ['/target-audience/kids'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/fascinators-and-hair-accessories/hair-clips',
                ],
                children: [],
              },
              {
                name: 'Hair Bow',
                targetAudiencePaths: ['/target-audience/kids'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/fascinators-and-hair-accessories/hair-bow',
                ],
                children: [],
              },
              {
                name: 'Hair Tie',
                targetAudiencePaths: ['/target-audience/kids'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/fascinators-and-hair-accessories/scrunchies-and-hair-ties',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Hats, Scarves & Gloves',
            targetAudiencePaths: ['/target-audience/kids'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves',
            ],
            children: [
              {
                name: 'Hats',
                targetAudiencePaths: ['/target-audience/kids'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/hats',
                ],
                children: [],
              },
              {
                name: 'Beanies',
                targetAudiencePaths: ['/target-audience/kids'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/accessories/fashion-accessories/hats-scarves-and-gloves/beanies',
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        name: 'Boyswear (3-16 years)',
        targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
        userCategoryPaths: [],
        productCategoryPaths: ['/product-category/clothing', '/product-category/shoes'],
        children: [
          {
            name: 'Shirts & Polos',
            targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/tops'],
            children: [
              {
                name: 'Shirts',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/tops/shirts/short-sleeve-shirts',
                ],
                children: [],
              },
              {
                name: 'Polos',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/polos/short-sleeve-polos'],
                children: [],
              },
            ],
          },
          {
            name: 'Boys Swimwear',
            targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/swimwear'],
            children: [
              {
                name: 'Swim Briefs',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/swim-briefs'],
                children: [],
              },
              {
                name: 'Boardshorts',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/boardshorts'],
                children: [],
              },
              {
                name: 'Rash Vests',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/swimwear/rash-vests'],
                children: [],
              },
            ],
          },
          {
            name: 'Bottoms',
            targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: [
              '/product-category/clothing/pants',
              '/product-category/clothing/shorts',
            ],
            children: [
              {
                name: 'Pants',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/pants/pants'],
                children: [],
              },
              {
                name: 'Shorts',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/shorts/casual-shorts'],
                children: [],
              },
            ],
          },
          {
            name: 'Boys Shoes',
            targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/shoes'],
            children: [
              {
                name: 'Casual Shoes',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/shoes/sneakers/lifestyle-sneakers'],
                children: [],
              },
            ],
          },
          {
            name: 'T-Shirts & Singlets',
            targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/tops/t-shirts'],
            children: [
              {
                name: 'T-Shirts',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/tops/t-shirts/t-shirts'],
                children: [],
              },
            ],
          },
          {
            name: 'Knitwear, Jumpers & Sweats',
            targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/sweatshirts-and-hoodies'],
            children: [
              {
                name: 'Hoodies',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/sweatshirts-and-hoodies/hoodies',
                ],
                children: [],
              },
              {
                name: 'Sweatshirts',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: [
                  '/product-category/clothing/sweatshirts-and-hoodies/sweatshirts',
                ],
                children: [],
              },
            ],
          },
          {
            name: 'Coats & Jackets',
            targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
            userCategoryPaths: [],
            productCategoryPaths: ['/product-category/clothing/coats-and-jackets'],
            children: [
              {
                name: 'Jacket',
                targetAudiencePaths: ['/target-audience/kids/boys', '/target-audience/kids/unisex'],
                userCategoryPaths: [],
                productCategoryPaths: ['/product-category/clothing/coats-and-jackets/jackets'],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
];
