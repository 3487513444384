import { animateCartIcon } from '@redux/cart/cartSlice';
import { useAppDispatch, useAppSelector } from '@redux/store';

export const useAnimateCartIcon = () => {
  const dispatch = useAppDispatch();
  const shouldAnimateCartIcon = useAppSelector(state => state.cart.shouldAnimateCartIcon);

  const startCartIconAnimation = () => {
    // setting the flag to redux, so we can make use of this flag when product is added in the cart.
    dispatch(animateCartIcon(true));
    // Need to reset the flag to false after the animation. so that we can reuse it when other product is added to cart.
    // we can either use animateCartIcon function or we can create resetanimateCartIcon in redux to change the flag value
    setTimeout(() => dispatch(animateCartIcon(false)), 1500);
  };

  return {
    startCartIconAnimation,
    shouldAnimateCartIcon,
  };
};
