import { Accordion } from '@ui/core';
import { cx } from '@ui/utils';
import { NavigationTreeType } from '@api/common/browse';
import { ROUTES } from '@constants/route';
import NavLink from '../NavLink';

type DynamicNavAccordionProps = {
  navigationTree: NavigationTreeType[];
  depth?: number;
  isCityBeachUser: boolean;
  isKidStuffUser: boolean;
  isCombatSportsUser?: boolean;
  parentName?: string;
  brandSlug: string;
};

const styles = {
  label: 'py-1.5',
  itemTitle: 'text-sm',
  content: 'py-0',
  control: 'px-0 pl-0.5',
  item: 'border-none',
  chevron: 'text-white w-4 h-4',
};

const DynamicNavAccordion = ({
  navigationTree,
  depth = 0,
  isCityBeachUser,
  isKidStuffUser,
  isCombatSportsUser,
  parentName = '',
  brandSlug,
}: DynamicNavAccordionProps) => {
  const renderNavAccordion = () => {
    return navigationTree?.map(item => {
      const hasChildren = item?.children?.length > 0;
      return (
        <Accordion.Item key={parentName + item.name} value={item.name}>
          <Accordion.Control
            classNames={{
              chevron: cx(
                !hasChildren && 'hidden',
                (isCityBeachUser || isKidStuffUser || isCombatSportsUser) && '!text-andisor-navy'
              ),
            }}
          >
            <NavLink
              className={cx(
                'text-white',
                (isCityBeachUser || isKidStuffUser || isCombatSportsUser) && 'text-andisor-navy'
              )}
              href={{
                pathname: `${ROUTES.BUYERS.BROWSE}/brand/${brandSlug}`,
                query: {
                  productCategoryPaths: (item?.productCategoryPaths || []) as string[],
                  targetAudiencePaths: (item?.targetAudiencePaths || []) as string[],
                  userCategoryPaths: (item?.userCategoryPaths || []) as string[],
                },
              }}
              highlightActiveLink={depth === 0}
              highlightColor={
                isCityBeachUser || isKidStuffUser || isCombatSportsUser
                  ? 'text-andisor-blue'
                  : undefined
              }
            >
              <span className="text-base mobile:text-sm">{item.name}</span>
            </NavLink>
          </Accordion.Control>
          <Accordion.Panel>
            {hasChildren && (
              <DynamicNavAccordion
                navigationTree={item.children}
                depth={depth + 1}
                isCityBeachUser={isCityBeachUser}
                isKidStuffUser={isKidStuffUser}
                isCombatSportsUser={isCombatSportsUser}
                parentName={item.name}
                brandSlug={brandSlug}
              />
            )}
          </Accordion.Panel>
        </Accordion.Item>
      );
    });
  };

  return <Accordion classNames={styles}>{renderNavAccordion()}</Accordion>;
};

export default DynamicNavAccordion;
