import React, { useCallback, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { useMediaQuery } from '@mantine/hooks';
import { Burger } from '@ui/core';
import { cx } from '@ui/utils';
import citybeachNavtree from '@layout/buyers/citybeachNavtree';
import { useAppSelector } from '@redux/store';
import useIsCombatSportsUser from '@hooks/useIsCombatSportsUser';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';
import { DYNAMIC_NAV_ROUTES, ROUTES } from '@constants/route';
import DynamicHoverMenu from './HoverMenu/DynamicHoverMenu';
import DynamicMobileMenu from './MobileMenu/DynamicMobileMenu';
import NavActions from './NavActions/NavActions';
import SearchBoxContainer from './SearchBox/SearchBoxContainer';
import styles from './Navbar.module.css';

type DynamicNavbarProps = {
  brandId: number;
  isMyerUser: boolean;
  isFrontlineUser: boolean;
  isCityBeachUser: boolean;
  isKidStuffUser: boolean;
  isMobileMenuOpen: boolean;
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const DynamicNavbar = ({
  brandId,
  isMyerUser,
  isFrontlineUser,
  isCityBeachUser,
  isKidStuffUser,
  isMobileMenuOpen,
  setMobileMenuOpen,
}: DynamicNavbarProps) => {
  const matches = useMediaQuery('(max-width: 64em)');
  const { isNewBalanceUser } = useIsNewBalanceUser();
  const { isCombatSportsUser } = useIsCombatSportsUser();

  const router = useRouter();
  const brandSlug = router.query.brandSlug as string;

  const navigationTree = useAppSelector(state =>
    isCityBeachUser
      ? {
          brandId: 0,
          data: citybeachNavtree,
          brandSlug,
          brandName: '',
        }
      : state.common.navigationTrees[
          isCombatSportsUser ? ROUTES.COMMON.COMBAT_SPORTS_SLUG : brandSlug
        ]
  );

  const closeMobileMenu = useCallback(() => {
    setMobileMenuOpen(false);
  }, [setMobileMenuOpen]);

  useEffect(() => {
    // close mobile menu if open when window is resized to desktop
    if (!matches) closeMobileMenu();
  }, [matches, closeMobileMenu]);

  useEffect(() => {
    // close mobile menu if open on route change
    const completeHandler = () => closeMobileMenu();
    Router.events.on('routeChangeComplete', completeHandler);
    return () => {
      Router.events.off('routeChangeComplete', completeHandler);
    };
  }, [closeMobileMenu]);

  return (
    <nav
      className={cx(
        'flex flex-col items-center justify-center bg-andisor-navy px-10 py-4 font-body text-base text-white mobile:px-5',
        isMobileMenuOpen ? styles.mobileMenu : styles.desktopMenu,
        (isMyerUser || isFrontlineUser || isNewBalanceUser) && 'bg-black text-white',
        (isCityBeachUser || isKidStuffUser || isCombatSportsUser) &&
          'border-b bg-white text-andisor-navy'
      )}
    >
      <div className="flex w-full max-w-8xl items-center justify-center gap-7">
        <div className="flex flex-1 items-center gap-10 mobile:gap-5">
          <Link
            href={
              isCombatSportsUser
                ? `/brands/${ROUTES.COMMON.COMBAT_SPORTS_SLUG}`
                : ROUTES.COMMON.HOME
            }
          >
            {isMyerUser ? (
              <div className="relative h-[60px] w-[140px] overflow-hidden tablet:h-[42px] tablet:w-[102px]">
                <Image src="/assets/images/myer-logo-light.svg" alt="Andisor" fill />
              </div>
            ) : isFrontlineUser ? (
              <div className="relative h-[58px] w-[62px] overflow-hidden tablet:h-[48px] tablet:w-[50px]">
                <Image src="/assets/images/frontline-logo.png" alt="Andisor" fill />
              </div>
            ) : isNewBalanceUser ? (
              <div className="relative h-[58px] w-[105px] overflow-hidden tablet:h-[48px] tablet:w-[50px]">
                <Image src="/assets/images/newbalance_logo_light.png" alt="Andisor" fill />
              </div>
            ) : isCityBeachUser ? (
              <div className="relative h-[48px] w-[120px] overflow-hidden">
                <Image src="/assets/images/citybeach_logo.png" alt="Andisor" fill />
              </div>
            ) : isKidStuffUser ? (
              <div className="relative h-[40px] w-[120px] overflow-hidden">
                <Image src="/assets/images/kidstuff_logo.png" alt="Andisor" fill />
              </div>
            ) : isCombatSportsUser ? (
              <div className="relative h-[60px] w-[107px] overflow-hidden">
                <Image src="/assets/images/adidas_logo.jpg" alt="Andisor" fill />
              </div>
            ) : (
              <div className="relative h-[63px] w-[150px] overflow-hidden tablet:h-[50px] tablet:w-[120px]">
                <Image src="/assets/logo/logo_full_light.svg" alt="Andisor" fill />
              </div>
            )}
            <span className="sr-only">Home</span>
          </Link>
          <DynamicHoverMenu
            isCityBeachUser={isCityBeachUser}
            isKidStuffUser={isKidStuffUser}
            navigationTree={navigationTree}
            isBuyerBrandPage={DYNAMIC_NAV_ROUTES.includes(router.pathname)}
          />
          <div className={cx('flex-1 tablet:hidden', !navigationTree && 'ml-auto max-w-sm')}>
            <SearchBoxContainer
              brandId={brandId}
              brandSlug={isCombatSportsUser ? ROUTES.COMMON.COMBAT_SPORTS_SLUG : brandSlug}
              isBuyerBrandPage={
                isCombatSportsUser ? true : DYNAMIC_NAV_ROUTES.includes(router.pathname)
              }
              isCityBeachUser={isCityBeachUser}
            />
          </div>
        </div>
        <div className="ml-4">
          <div className="flex items-center gap-5 text-sm tablet:hidden">
            <NavActions />
          </div>
          <div className="hidden tablet:block">
            <Burger
              opened={isMobileMenuOpen}
              onClick={() => setMobileMenuOpen(prevOpen => !prevOpen)}
              color={isCityBeachUser || isKidStuffUser || isCombatSportsUser ? 'black' : 'white'}
              aria-label="Toggle navigation"
              size="sm"
              className="flex items-center justify-end p-0"
              transitionDuration={150}
            />
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <DynamicMobileMenu
          navigationTree={navigationTree}
          isCityBeachUser={isCityBeachUser}
          isKidStuffUser={isKidStuffUser}
          isCombatSportsUser={isCombatSportsUser}
        />
      )}
    </nav>
  );
};

export default DynamicNavbar;
