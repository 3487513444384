import { useMemo } from 'react';
import { z } from 'zod';
import { getHomepageCMSSchema } from '@api/buyers/cms';
import { useAuth } from '@hooks/useAuth';
import { LANDING_PAGE_DEMO_PATH_CONFIG_MAP } from '@data/demo/demoUsers';
import { LANDING_PAGE_PROD_PATH_CONFIG_MAP } from '@data/prod/prodUsers';
import { LANDING_PAGE_STAGING_PATH_CONFIG_MAP } from '@data/staging/stagingUsers';
import { ROLES } from '@constants';

export type LandingPageDataType =
  | {
      default: z.infer<typeof getHomepageCMSSchema>;
      [key: string]: z.infer<typeof getHomepageCMSSchema>;
    }
  | undefined;

const getLandingPagePathConfigMapForEnv = () => {
  switch (process.env.NEXT_PUBLIC_ENV) {
    case 'dev': {
      return {};
    }
    case 'staging': {
      return LANDING_PAGE_STAGING_PATH_CONFIG_MAP;
    }
    case 'demo': {
      return LANDING_PAGE_DEMO_PATH_CONFIG_MAP;
    }
    case 'prod': {
      return LANDING_PAGE_PROD_PATH_CONFIG_MAP;
    }
    default: {
      return {};
    }
  }
};

export const useHomepageCMS = (landingPageData: LandingPageDataType) => {
  const { user } = useAuth();
  const isRetailer = user?.role === ROLES.RETAILER;

  const parentId = isRetailer ? user?.parentRetailer?.id : user?.parentBrand?.id;

  const configMap = getLandingPagePathConfigMapForEnv();

  const cmsData = useMemo(() => {
    if (!landingPageData) return null;
    // prelogin, use default landing page data
    if (!parentId) return landingPageData.default;
    // determine which landing page data key to use based on user's parent id
    const matchingKey = Object.keys(configMap).find((key: string) =>
      configMap[key].includes(parentId)
    );
    // fallback to default if no match found
    return matchingKey
      ? landingPageData[matchingKey] ?? landingPageData.default
      : landingPageData.default;
  }, [landingPageData, parentId, configMap]);

  return cmsData;
};
