import Link from 'next/link';
import { cx } from '@ui/utils';
import useIsCombatSportsUser from '@hooks/useIsCombatSportsUser';

type PromoBannerProps = {
  promotionText: string;
  promotionURL: string;
  isMyerUser: boolean;
  isFrontlineUser: boolean;
  isNewBalanceUser: boolean;
  isCityBeachUser: boolean;
  isKidStuffUser: boolean;
  isMobileMenuOpen: boolean;
};

const PromoBanner = ({
  promotionText,
  promotionURL,
  isMyerUser,
  isFrontlineUser,
  isNewBalanceUser,
  isCityBeachUser,
  isKidStuffUser,
  isMobileMenuOpen,
}: PromoBannerProps) => {
  const { isCombatSportsUser } = useIsCombatSportsUser();
  const bannerProps = {
    className: cx(
      'flex justify-center px-5 py-2 font-body text-xs',
      isMyerUser && 'bg-[#FD4019] text-white',
      isFrontlineUser && 'bg-[#ee2e24] text-white',
      isNewBalanceUser && 'bg-[#e11835] text-white',
      isCityBeachUser && 'bg-[#0A2F87] text-[#F8E300]',
      isKidStuffUser && 'bg-[#E2211B] text-white',
      isCombatSportsUser && 'bg-black text-white',
      isMobileMenuOpen && 'hidden'
    ),
    style: {
      backgroundImage:
        !isMyerUser &&
        !isFrontlineUser &&
        !isNewBalanceUser &&
        !isCityBeachUser &&
        !isKidStuffUser &&
        !isCombatSportsUser
          ? 'linear-gradient(90deg, rgb(var(--color-pink-4)) 0%, rgb(var(--color-blue-6)) 100%)'
          : '',
    },
    children: <span className="flex-1 text-center font-bold">{promotionText}</span>,
  };

  if (promotionURL) {
    return <Link href={promotionURL} target="_blank" {...bannerProps} />;
  }

  return <div {...bannerProps} />;
};

export default PromoBanner;
