import { z } from 'zod';
import { getHeroNavigationSchema } from '@api/common/browse';

export const NEW_BALANCE_NAV_ITEMS = ['Men', 'Women', 'Kids & Baby'];
export const NEW_BALANCE_BRAND_ID_DEMO = '89';
export const NEW_BALANCE_BRAND_ID_DEV = '105';

export const filteredNewBalanceNav = (_navData: z.infer<typeof getHeroNavigationSchema>) => {
  return NB_NAV_LINKS;
};

const NB_NAV_LINKS = [
  {
    name: 'Women',
    path: '/navigation/women',
    children: [
      {
        name: 'Shoes',
        path: '/navigation/women/shoes',
        children: [
          {
            name: 'Sneakers',
            path: '/navigation/women/shoes/sneakers',
            children: [
              {
                name: 'Lifestyle Sneakers',
                path: '/navigation/women/shoes/sneakers/lifestyle-sneakers',
              },
              {
                name: 'Active Sneakers',
                path: '/navigation/women/shoes/sneakers/active-sneakers',
              },
            ],
          },
          {
            name: 'Performance',
            path: '/navigation/women/shoes/performance',
            children: [
              {
                name: 'Running',
                path: '/navigation/women/shoes/performance/running',
              },
            ],
          },
        ],
      },
      {
        name: 'Clothing',
        path: '/navigation/women/clothing',
        children: [
          {
            name: 'Pants',
            path: '/navigation/women/clothing/pants',
            children: [
              {
                name: 'Leggings',
                path: '/navigation/women/clothing/pants/leggings',
              },
            ],
          },
          {
            name: 'Activewear',
            path: '/navigation/women/clothing/activewear',
            children: [
              {
                name: 'Fitness Tops',
                path: '/navigation/women/clothing/activewear/fitness-tops',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Men',
    path: '/navigation/men',
    children: [
      {
        name: 'Shoes',
        path: '/navigation/men/shoes',
        children: [
          {
            name: 'Sneakers',
            path: '/navigation/men/shoes/sneakers',
            children: [
              {
                name: 'Lifestyle Sneakers',
                path: '/navigation/men/shoes/sneakers/lifestyle-sneakers',
              },
            ],
          },
          {
            name: 'Performance',
            path: '/navigation/men/shoes/performance',
            children: [
              {
                name: 'Running',
                path: '/navigation/men/shoes/performance/running',
              },
            ],
          },
        ],
      },
      {
        name: 'Clothing',
        path: '/navigation/men/clothing',
        children: [
          {
            name: 'T-Shirts & Singlets',
            path: '/navigation/men/clothing/t-shirts-and-singlets',
            children: [
              {
                name: 'T-Shirts',
                path: '/navigation/men/clothing/t-shirts-and-singlets/t-shirts',
              },
            ],
          },
          {
            name: 'Sweats & Hoodies',
            path: '/navigation/men/clothing/sweats-and-hoodies',
            children: [
              {
                name: 'Hoodies',
                path: '/navigation/men/clothing/sweats-and-hoodies/hoodies',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Kids & Baby',
    path: '/navigation/kids-and-baby',
    children: [
      {
        name: 'Boyswear (3-16 years)',
        path: '/navigation/kids-and-baby/boyswear-3-16-years',
        children: [
          {
            name: 'Boys Shoes',
            path: '/navigation/kids-and-baby/boyswear-3-16-years/boys-shoes',
            children: [
              {
                name: 'Sneakers',
                path: '/navigation/kids-and-baby/boyswear-3-16-years/boys-shoes/sneakers',
              },
            ],
          },
        ],
      },
      {
        name: 'Girlswear (3-16 years)',
        path: '/navigation/kids-and-baby/girlswear-3-16-years',
        children: [
          {
            name: 'Shoes',
            path: '/navigation/kids-and-baby/girlswear-3-16-years/shoes',
            children: [
              {
                name: 'Sneakers',
                path: '/navigation/kids-and-baby/girlswear-3-16-years/shoes/sneakers',
              },
            ],
          },
        ],
      },
    ],
  },
];
